@import '../../../style.scss';

.traceurForm{
    width: 100%;
    .product-container{
        .product-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            border-radius: 5px;
            .product-left{
                display: flex;
                flex-direction: column;
                gap: 7px;
                .product-h2{
                    font-size: 1rem;
                    font-weight: 800;
                    background: linear-gradient(90deg, #13add8bd, #000000ee);
                    -webkit-background-clip: text;
                    color: transparent;
                    text-transform: uppercase;
                    @include mobileXR{
                        font-size: .7rem;
                    }
                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                    font-style: italic;
                    @include mobileXR{
                        display: none;
                    }
                }
            }

        }
       .product-wrapper{
            background: #fff;
            padding: 40px;
            border-radius: 5px;
            @include mobileXR{
                padding: 20px;
            }
            
        .product-container-bottom{
            position: relative;
            width: 100%;
            @include miniTablette{
                width: 100%;
                overflow-x: scroll;
            }
            @include mobileXR{
                grid-template-columns: repeat(1, 1fr);
                gap: 15px;
            }
            .form-input{
                padding: 8px;
                border: none;
                outline: none;
                border: 1px solid #c5c5c5;
                border-radius: 5px;
                font-size: 12px;

                    &::-webkit-input-placeholder {
                        font-size: 12px;
                    }
            }
            .form-controle{
                display: flex;
                flex-direction: column;
                gap: 20px;
                @include mobileXR{
                    gap: 10px;
                }
                label{
                    display: flex;
                    align-items: center;
                    font-size: .9rem;
                    color: #797979;
                    @include mobileXR{
                       font-family: .8rem;
                    }
                    .icon_plus{
                        padding-left: 5px;
                        cursor: pointer;
                    }
                }
                .form-input{
                    padding: 10px;
                    border: none;
                    outline: none;
                    border: 1px solid #c5c5c5;
                    border-radius: 5px;
                    font-size: 12px;

                    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
                        font-size: 11px;
                    }
                }
                .form-desc{
                    height: 100px;
                    width: 100%;
                    resize: none;
                }
            }
        }
        .form-controleFile{
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                font-size: .9rem;
                color: #797979;
                padding-bottom: 20px;
            }
            .form-file{
                width: 100%;
                border: 1px solid #c5c5c5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height:150px;
                gap: 10px;
                border-radius: 5px;
                cursor: pointer;
                label{
                    font-size: .9rem;
                    color: #797979;
                }
                .cloud-icon{
                    color: rgb(1, 35, 138);
                    font-size: 1.8rem;
                }
                span{
                    font-size: .9rem;
                    color: #929292;
                    letter-spacing: 1px;
                }
            }
        }
        .form-submit{
            display: flex;
            gap: 10px;
            padding-top: 30px;
            .btn-submit{
                padding: 15px 20px;
                border: none;
                color: #fff;
                background-color: #007bff;
                border-color: #007bff;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                &:hover{
                    background-color: #0056b3;
                    border-color: #0056b3;
                }
            }
            .btn-submit-loading {
                opacity: 0.7; /* Exemple : réduire l'opacité pendant le chargement */
                pointer-events: none; /* Empêcher les interactions pendant le chargement */
              }
            .btn-annuler{
                background: #464444;
                cursor: pointer;
            }
        }
       }
       
    }
}