.permission-page {
    width: 100%;
    padding: 20px;
    background: #fff;
  }
  
  .permission-page h1 {
    margin-bottom: 10px;
    font-size: 17px;
    text-transform: uppercase;
  }
  
  .permission-page .ant-table {
    margin-bottom: 20px;
  }
  .permission_h1{
    font-size: 1.5rem;
    padding: 0;
  }
  .permission_desc{
    color: #555;
    font-size: .8rem;
    padding: 20px 0;
  }

  @media screen and (max-width: 568px){
    .table_permission{
      overflow: auto;
    }
  }

  @media screen and (max-width: 375px){
    .outlet-wrapper{
        padding: 20px;
    }
  }