@import '../../style.scss';

.pieChart{
    position: relative;
    flex: 2.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
    border: 1px solid rgba(3, 3, 109, 0.171);
    border-radius: 20px;
    padding: 20px;
    .depenses{
        width: 100%;
        text-align: left;
        background: linear-gradient(90deg, #13add8bd, #000000c2);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 700;
        padding-top: 5px;
        font-size: 1.2rem;
        @include mobileXR{
            font-size: .9rem;
        }
    }
    @include mobileXR{
        width: 100%;
        padding: 8px;
        height: auto;
    }
    @include mobile12pro{
        height: auto;
    }
}