@import '../../style.scss';

.PaiementChart{
    flex: 5;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
    border: 1px solid rgba(3, 3, 109, 0.171);
    border-radius: 20px;
    padding: 20px;
    height: auto;
    @include miniTablette{
        height: auto;
    }
    @include mobileXR{
        width: 100%;
        padding: 8px;
        height: auto;
    }
    @include mobile12pro{
        height: auto;
    }
    .paiement_h2{
        background: linear-gradient(90deg, #13add8bd, #000000c2);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 700;
        font-size: 1.2rem;
        @include mobileXR{
           font-size: .9rem;
       }
    }
}