.factureEffectue{
    width: 100%;
    .facture_title_date{
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        margin-bottom: 20px;
        border-radius: 5px;
        .facture_h1{
            font-weight: 400;
            font-size: 1.1rem;
            background: linear-gradient(90deg, #13add8bd, #000000ee);
            -webkit-background-clip: text;
            color: transparent;
            text-transform: uppercase;
        }
        span{

        }
    }
    .factureEffectue_wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .factureEffectue_left{
            flex: 4;
            width: 100%;
            overflow-x: scroll;
            padding: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            border-radius: 5px;
            .facture_rows_title{
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 10px;
                .facture_row_title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .facture_h2{
                        font-size: 1rem;
                        background: linear-gradient(90deg, #13add8bd, #000000ee);
                        -webkit-background-clip: text;
                        color: transparent;
                        text-transform: uppercase;
                    }
                    span{
                        font-size: .9rem;
                        background: linear-gradient(90deg, #13add8bd, #000000ee);
                        -webkit-background-clip: text;
                        color: transparent;
                        text-transform: uppercase;
                    }
                }
                .row_inputs{
                    width: 250px;
                }
            }
            .facture_tab{
                h3{
                    font-size: .9rem;
                    color: #020114;
                }
            }
        }
        .factureEffectue_right{
            flex: 1;
            padding: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .facture_h2{
                font-size: 1rem;
                background: linear-gradient(90deg, #13add8bd, #000000ee);
                -webkit-background-clip: text;
                color: transparent;
                text-transform: uppercase;
                margin-bottom: 10px;
                text-transform:uppercase;
            }
            .factureEffectue_rows{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .facture_desc{
                    font-size: .8rem;
                    color: #7c7c7c;
                    span{
                        color: red;
                    }
                }
            }
        }
        .facture_montant_rows{
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-top: 1px solid #f0f0f0;
            .facture_desc{
                font-size: .8rem;
                color: #7c7c7c;
                padding-top: 10px;
                span{
                    color: red;
                }
            }
        }
    }
}