@import '../../style.scss';

.client{
    position: relative;
    width: 100%;
    .client_wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        .client_wrapper_top{
            background: #fff;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            width: 100%;
            overflow: auto;
            @include miniTablette{
                padding: 10px;
            }
            .client_text_row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include mobileXR{
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                }
                .client_text_left{
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    .client_h2{
                        font-size: 1rem;
                        font-weight: 800;
                        background: linear-gradient(90deg, #13add8bd, #000000ee);
                        background-clip: text;
                        color: transparent;
                        text-transform: uppercase;
                        @include miniTablette{
                            font-size: .9rem;
                        }
                    }
                    .client_span{
                        font-size: .7rem;
                        color: #686868d8;
                        font-style: italic;
                        @include miniTablette{
                            font-size: .6rem;
                        }
                    }
                }
                .client_row_number{
                    padding: 10px;
                    background: #fff;
                    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
                    border: 1px solid rgba(3, 3, 109, 0.171);
                    border-radius: 5px;
                    @include mobileXR{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        width: 100%;
                    }
                    .client_row_span{
                        padding: 2px 0;
                        display: flex;
                        justify-content: space-between;
                        gap: 20px;
                        border-bottom: 1px solid #f5f5f5;
                        .client_span_title{
                            font-size: .8rem;
                            font-weight: 800;
                            background: linear-gradient(90deg, #13add8bd, #000000ee);
                            background-clip: text;
                            color: transparent;
                            text-transform: uppercase;
                            @include miniTablette{
                                font-size: .8rem;
                            }
                            @include mobileXR{
                                font-size: .7rem;
                            }
                        }
                    }
                    .client_span_title{
                        font-size: .9rem;
                        font-weight: 800;
                        background: linear-gradient(90deg, #13add8bd, #000000ee);
                        background-clip: text;
                        color: transparent;
                        text-transform: uppercase;
                        @include miniTablette{
                            font-size: .8rem;
                        }
                        @include mobileXR{
                            font-size: .7rem;
                        }
                    }
                }
                .client_text_right{
                    button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(90deg, #13add8bd, #000000c2);
                        color: #fff;
                        border: none;
                        padding: 8px;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }
            }
        }
        .filters{
            padding: 20px;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 10px;
        }
        .client_wrapper_center{
            display: flex;
            flex-direction: column;
            gap: 20px;
            background: #fff;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            @include miniTablette{
                padding: 10px;
            }
            .client_wrapper_center_bottom{
                .product-bottom-top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 30px;
                    .product-bottom-left{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .product-icon{
                            color: #fff;
                            background: linear-gradient(90deg, #13add8bd, #000000c2);
                            padding: 10px;
                            border-radius: 5px;
                            font-size: 14px;
                            cursor: pointer;
                            transition: .5s;
                            &:hover{
                                background:rgb(6, 60, 223);
                            }
                            @include miniTablette{
                                font-size: 12px;
                            }
                        }
                        .product-row-search{
                            border: 1px solid #c7c7c7;
                            border-radius: 5px;
                            padding: 6px 8px;
                            @include miniTablette{
                                padding: 5px 7px;
                                width: 100%;
                            }
                            @include mobileXR{
                                padding: 5px 6px;
                                width: 100%;
                            }
                            .product-icon-plus{
                                @include miniTablette{
                                    display: none;
                                }
                            }
                            .product-search{
                                border: none;
                                outline: none;
                                padding-left: 10px;
                                font-size: 12px;
                                &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
                                    font-size: 12px;
                                }
                                @include miniTablette{
                                    padding-left: 8px;
                                    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
                                        font-size: 11px;
                                    }
                                }
                                @include mobileXR{
                                    font-size: 10px;
                                }
                                
                            }
                        }
                    }
                    .product-bottom-right{
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        @include miniTablette{
                            display: none;
                        }
                        .product-icon-pdf{
                            color: red;
                            font-size: 22px;
                            cursor: pointer;
                            border: 1px solid red;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        .product-icon-excel{
                            color: green;
                            font-size: 22px;
                            cursor: pointer;
                            border: 1px solid green;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        .product-icon-printer{
                            font-size: 22px;
                            cursor: pointer;
                            border: 1px solid black;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                    }
                }
                .table_client{
                    width: 100%;
                    overflow: auto;
                }
                .font-size-18 td,
                .font-size-18 th {
                font-size: 13px;
                }
            }
        }
    }
}