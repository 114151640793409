.factureForm{
    width: 100%;
    .factureForm_container{
        width: 100%;;
        padding: 20px;
        .factureForm_top{
            width: 100%;
            background-color: #007bff;
            color: #fff;
            padding: 5px 10px;
            margin-bottom: 20px;
            .facture_h2{
                font-size: 1rem;
                padding: 0;
                margin: 0;
            }
        }
        .factureForm_wrapper{
            .factureForm{
                display: flex;
                flex-direction: column;
                gap: 15px;
                .facture_controle{
                    .facture_label{
                        font-size: .8rem;
                        color: #555;
                    }
                }
                .facture_btn{
                    button{
                        padding: 15px 20px;
                        border: none;
                        color: #fff;
                        background-color: #007bff;
                        border-color: #007bff;
                        border-radius: 5px;
                        cursor: pointer;
                        &:hover{
                            background-color: #0056b3;
                            border-color: #0056b3;
                        }
                    }
                }
            }
        }
    }
}