@import '../../style.scss';

.pageViews{
    width: 100%;
    overflow: hidden;
    .pageViews_rows{
        display: grid;
        grid-template-columns: repeat(4,1fr) 0.2fr;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @include miniTablette{
             grid-template-columns: repeat(2,1fr);
        }
        @include mobileXR{
            grid-template-columns: repeat(2,1fr);
            gap: 10px;
        }
        @include mobile12pro{
            grid-template-columns: repeat(1,1fr);
            gap: 10px;
        }
        .pageViews_row{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
            border: 1px solid rgba(3, 3, 109, 0.171);
            border-radius: 20px;
            padding: 20px;
            cursor: pointer;
            .pageViews_left{
                display: flex;
                flex-direction: column;
                .pageViews_left_rond{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    .page_rond{
                        display: block;
/*                         background: linear-gradient(90deg, #13add8bd, #000000c2); */
                        background: rgb(2, 165, 89);
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        @include mobileXR{
                            height: 5px;
                            width: 5px;
                        }
                    }
                    .page_rondOp{
                        display: block;
                        background: #c0ce05f5;
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        @include mobileXR{
                            height: 5px;
                            width: 5px;
                        }
                    }
                    .page_rondTr{
                        display: block;
                        background: rgb(180, 3, 3);
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        @include mobileXR{
                            height: 5px;
                            width: 5px;
                        }
                    }
                    .page_rondV{
                        display: block;
                        background: blue;
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        @include mobileXR{
                            height: 5px;
                            width: 5px;
                        }
                    }
                    
                    .pageViews_h5{
                        margin: 0;
                        padding: 0;
                        font-size: .9rem;
                        color: rgba(3, 3, 31, 0.76);
                        font-weight: bolder;
                        @include miniTablette{
                            font-size: .8rem;
                        }
                        @include mobileXR{
                            font-size: .7rem;
                        }
                        @include mobile12pro{
                            font-size: .9rem;
                        }
                    }
                }
                .pageViews_h1{
                    margin: 0;
                    padding: 0;
                    font-size: 1.5rem;
                    font-weight: bold;
                    letter-spacing: 2px;
                    background: linear-gradient(90deg, #13add8bd, #000000c2);
                    -webkit-background-clip: text;
                    color: transparent;
                    @include miniTablette{
                        font-size: 1.9rem;
                        letter-spacing: 1px;
                   }
                   @include mobileXR{
                        font-size: 1.4rem;
                        letter-spacing: 0px;
                    }
                }
                .pageViews_span{
                    color: rgb(158, 157, 157);
                    font-size: .7rem;
                    letter-spacing: 1px;
                    @include miniTablette{
                        font-size: .7rem;
                        letter-spacing: 0px;
                    }
                    @include mobileXR{
                        font-size: .580rem;
                    }
                }
            }
            .pageViews_right{
                position: relative;
                .pageViews_right_img{
                    height: 40px;
                    width: 40px;
                    object-fit: cover;
                    @include miniTablette{
                        height: 40px;
                        width: 40px;
                    }
                    @include mobile12pro{
                        height: 45px;
                        width: 45px;
                    }
                }
            }
        }
        .rowTotals{
            width: 55px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 5px;
            border: 1px solid #c2c2c2bb;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            border-radius: 50%;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}