@import '../../style.scss';

.login_container{
    padding: 0;
    margin: 0;
    position: relative;
    height: 100vh;
    width: 100%;
    background: rgba(84, 84, 240, 0.856);
    display: flex;
    align-items: center;
    justify-content: center;
    .login_wrapper{
        display: flex;
        justify-content: space-around;
        height: 80vh;
        width: 60%;
        background: #f5f5f5f3;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(26, 26, 26, 0.75);
        box-shadow: 0px 0px 15px -10px rgba(20, 20, 20, 0.75);
        padding: 30px;
        border-radius: 3px;
        @include miniTablette{
            flex-direction: column-reverse;
            justify-content: center;
            height: 100vh;
            width: 100%;
            padding: 20px;
            background: #fff;
        }
        @include mobileXR{
            padding: 20px;
            height: 100%;
        }
        .login_left{
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(26, 26, 26, 0.75);
            box-shadow: 0px 0px 15px -10px rgba(20, 20, 20, 0.75);
            padding: 25px;
            border-radius: 3px;
            @include miniTablette{
                flex: 4;
                padding: 20px;
                box-shadow: none;
                gap: 10px;
            }
            @include mobileXR{
                gap: 10px;
            }
            .login_left_top{
                display: flex;
                flex-direction: column;
                gap: 8px;
                .login_h2{
                    padding: 0;
                    margin: 0;
                    font-size: 1.7rem;
                    font-weight:800;
                    color: rgb(7, 44, 77);
                    @include mobileXR{
                        font-size: 1.8rem;
                    }
                }
                .login_span{
                    font-size: .7rem;
                    color: #8d8d8d;
                    font-weight: 300;
                    letter-spacing: 1px;
                    .login_span_sous{
                        text-decoration: underline;
                        letter-spacing: 1px;
                        color: rgba(38, 38, 240, 0.836);
                        cursor: pointer;
                    }
                }
            }
            .login_control{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .login_controle_row{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .login_connecte{
                        font-size: .799rem;
                        text-decoration: underline;
                        letter-spacing: 1px;
                        color: rgba(38, 38, 240, 0.836);
                        cursor: pointer;
                    }
                }
                .login_label{
                    font-size: .8rem;
                    font-weight: 800;
                    color: rgb(7, 44, 77);
                }
                .login_input{
                    width: 100%;
                    padding: 10px 8px;
                    border: none;
                    outline: none;
                    border: 1px solid #c5c5c5;
                    border-radius: 5px;
                    &::placeholder{
                        font-size: .9rem;
                        @include mobileXR{
                            font-size: .8rem;
                        }
                    }
                    @include mobileXR{
                        font-size: .8rem;
                        padding: 12px 9px;
                    }
                    @include miniTablette{
                        padding: 12px 8px;
                    }
                }
                .login_password_wrapper{
                    border: 1px solid #c5c5c5;
                    .login_input{
                        border: none;
                        border-radius: 5px;
                    }
                }
            }
            .login_remember_row{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .login_row_checked{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .login_checkbox{
                        font-size: .7rem;
                        letter-spacing: 1px;
                        @include mobileXR{
                            font-size: .7rem;
                        }
                    }
                }
                .login_forgot{
                    font-size: .7rem;
                        @include mobileXR{
                            font-size: .7rem;
                        }
                    &:hover{
                        color: red;
                    }
                }
            }
            .login-btn{
                .btn{
                    width: 100%;
                    border: none;
                    padding: 10px;
                    background: rgba(78, 78, 228, 0.938);
                    color: #fff;
                    border-radius: 5px;
                    letter-spacing: 1px;
                    font-size: .899rem;
                    font-weight: 800;
                    cursor: pointer;
                    &:hover{
                        transition: 0.5s;
                        background: rgba(31, 31, 192, 0.938);
                    }
                    @include miniTablette{
                        padding: 12px;
                        font-size: .7rem;
                    }
                }
            }
            .login-ligne{
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: .8rem;
                color: #636363;
                font-weight: 100;
                .ligne{
                    flex: 1;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: rgba(78, 78, 228, 0.562);
                }
            }
            .login-rsx-rows{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;
                .btn-rx{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    border: none;
                    padding: 10px;
                    background: #fff;
                    @include miniTablette{
                        padding: 10px;
                    }
                }
                .fb{
                    border: 1px solid rgb(38, 38, 247);
                    color: rgb(38, 38, 247);
                    border-radius: 5px;
                    font-size: .7rem;
                }
                .google{
                    border: 1px solid rgb(170, 30, 30);
                    color: rgb(170, 30, 30);
                    border-radius: 5px;
                    font-size: .7rem;
                }
            }
        }
        .login_right{
            flex: 3;
            width: 100%;
            @include mobileXR{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .login-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include mobileXR{
                    width: 90%;
                    height: 90%;
                }
            }
        }
    }
}