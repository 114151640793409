.facturationRecu{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .facturation_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .facture_logo{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .facture_img{
                height: 80px;
                width: 80px;
                object-fit: cover;
            }
        }
        .facture_title_rows{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .facture_title_h2{
                width: 100%;
                text-align: center;
                font-weight: 700;
            }
            .facture_rows_num{
                width: 100%;
                .facture_date{
                    display: block;
                    width: 100%;
                    text-align: right
                }
               .facture_title_h3{
                width: 100%;
                text-align: center;
                text-decoration:underline;
                font-size: .9rem;
               } 
            }
            .facture_client{
                .facture_desc{
                    font-size: .8rem;
                    span{
                        text-decoration: underline;
                    }
                }
            }
            .facture_h5{
                display: block;
                width: 100%;
                text-align: center;
            }
            table{
                width: 100%;
                border-collapse: collapse;
                margin: 20px 0; 
                font-size: 16px; 
                text-align: left;
                thead{
                    tr{
                        th{
                            border: 1px solid #dddddd; 
                            padding: 8px; 
                            font-size: .8rem;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            border: 1px solid #dddddd; 
                            padding: 8px; 
                            font-size: .8rem;
                        }
                    }
                }

            }
            .facture_signature{
                width: 100%;
                margin: 20px 0 40px 0;
                h3{
                    font-size: .8rem;
                    display: block;
                    width: 100%;
                    text-align: right
                }
            }
            .facture_footer{
                hr{
                    height: 2px;
                }
                .facture_footer_rows{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    align-items: center;
                    justify-content: center;
                    h2{
                        font-size: .8rem;
                        margin: 0;
                        padding: 0;
                    }
                    span{
                        font-size: .6rem;
                    }
                }
            }
        }
    }
}