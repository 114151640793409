@import '../../style.scss';


.rightbar{
    position: relative;
    width: 100%;
    .rightbar_wrapper{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .rightbar_rows{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            height: 410px;
            @include miniTablette{
                display: flex;
                flex-direction: column;
                height: auto;
            }
        }
    }
}