.rapportDuJour{
    width: 100%;
    .rapport_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .rapport_wrapper_top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 15px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
            border: 1px solid rgba(3, 3, 109, 0.171);
            border-radius: 5px;
            .rapport_row{
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 5px;
                .rapport_img{
                    object-fit: cover;
                    width: 100%;
                    height: 110px;
                    width: 110px;
                }
                .rapport_montant{
                    font-size: 1.9rem;
                    font-weight: 900;
                    color: rgb(14, 25, 88);
                }
                .rapport_desc{
                    font-size: .7rem;
                    color: #777777;
                    font-style: italic;
                }
            }

        }
        .rapport_wrapper_bottom{
            width: 100%;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 15px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
            border: 1px solid rgba(3, 3, 109, 0.171);
            border-radius: 5px;
        }
    }
}