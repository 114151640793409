.rapport{
    .rapport_wrapper{
        background: #fff;
        padding: 20px;
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .rapport_title-rows{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
            border: 1px solid rgba(3, 3, 109, 0.171);
            border-radius: 5px;
            .rapport_row_search{
                display: flex;
                align-items: center;
                gap: 10px;
                .rapport_input{
                    width: 100%;
                    padding: 10px;
                    border: none;
                    outline: none;
                    border: 1px solid #c5c5c5;
                    border-radius: 5px;
                    font-size: .7rem;
                }
            }
        }
        .rapport_title{
            font-size: 1.3rem;
            font-weight: 800;
        }
        .rapport_rows{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            .rapport_row{
                position: relative;
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
                border: 1px solid rgba(3, 3, 109, 0.171);
                border-radius: 5px;
                .rapport_img{
                    width: 50%;
                }
                .rapport_rows_info{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    .rapport_sous_titles{
                        font-weight: 700;
                    }
                    .rapport_sous_title{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        font-size: .9rem;
                        font-weight: 300;
                        display: flex;
                        padding: 8px 0px;
                        border-bottom: 1px solid #c4c4c477;
                        strong{
                            font-size: .8rem;
                            font-weight: 700;                        }
                    }
                }
            }
        }
    }
}