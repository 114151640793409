@import '../../../style.scss';

.recharge_form{
    position: relative;
    width: 100%;
    overflow: auto;
    .recharge_form_wrapper{
        padding: 20px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        border-radius: 5px;
        @include miniTablette{
            padding: 10px;
        }
        .recharge_title_rows{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #e7e7e74d;
            border: 1px solid rgba(3, 3, 109, 0.171);
            padding: 20px 10px;
            margin-bottom: 20px;
            border-radius: 5px;
            @include mobileXR{
                display: grid;
                grid-template-columns: repeat(1,1fr);
                gap: 10px;
            }
            .recharge_client_title{
                font-size: 1rem;
                font-style: italic;
                @include miniTablette{
                    font-size: .7rem;
                }
            }
            .recharge_row_search{
                border: 1px solid #c7c7c7;
                border-radius: 5px;
                padding: 6px 8px;
                background: #fff;
                @include miniTablette{
                    padding: 5px 6px;
                    width: 55%;
                }
                @include mobileXR{
                    width: 80%;
                }
                .product-icon-plus{
                    @include miniTablette{
                        font-size: .7rem;
                    }
                }
                .product-search{
                    border: none;
                    outline: none;
                    padding-left: 10px;
                    font-size: 12px;
                    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
                        font-size: 11px;
                    }
                    @include miniTablette{
                        padding-left: 8px;
                        &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .skeleton-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh; // Full viewport height
            gap: 20px; // Spacing between skeleton items
        }
        .recharge_client{
            font-size: 1rem;
            color: rgb(59, 59, 59);
            text-transform:uppercase;
            margin-bottom: 20px;
        }
        .recharge_form_rows{
            display: grid;
            grid-template-columns: repeat(4,1fr);
            align-items: center;
            width: 100%;
            gap: 20px;
            @include miniTablette{
                grid-template-columns: repeat(2,1fr);
                gap: 10px;
            }
            .recharge_form_row{
                padding: 20px;
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                border: 1px solid rgba(3, 3, 109, 0.171);
                border-radius: 5px;
                cursor: pointer;
                @include miniTablette{
                    padding: 10px;
                }
                &:hover{
                    border: 1px solid rgba(64, 64, 241, 0.425);
                    transform: scale(1.1);
                }
                .recharge_img{
                    width: 25%;
                    @include miniTablette{
                        width: 20%;
                    }
                    @include mobileXR{
                        width: 30%;
                    }
                    
                }
                .recharge_form_bottom{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding-top: 10px;
                    .recharge_span{
                        font-size: .8rem;
                        color: #1a1c1d;
                        font-weight: bold;
                        @include miniTablette{
                            font-size: .690rem;
                        }
                        @include mobileXR{
                            font-size: .655rem;
                        }
                    }
                }
            }
        }
    }
}