
.confirmation-modal .ant-modal-content {
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  .confirmation-modal .ant-modal-header {
    background-color: #007BFF;
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .confirmation-modal .ant-modal-title {
    color: white;
  }
  
  .confirmation-modal .ant-modal-footer {
    display: flex;
    justify-content: flex-end; 
    gap: 10px; 
  }
  
  .confirmation-modal .modal-text {
    font-size: 16px; 
    margin-bottom: 20px;
    
  }
  
  .confirmation-modal .modal-data p {
    margin: 5px 0; 
    font-size: 14px;
  }
  

  .react-select-container {
    width: 100%;
  }
  
  .react-select__control {
    min-height: 40px;
    height: 40px;
  }
  
  .react-select__value-container {
    height: 40px;
    padding: 0 6px;
  }
  
  .react-select__input {
    margin: 0px;
  }
  
  .react-select__indicators {
    height: 40px;
  }
  
  .tableau_add .ant-table {
    overflow: auto;
  }
  
  .ant-input {
    min-height: 40px;
  }
  .react-select-container {
    width: 100%;
  }
  
  .react-select__control {
    min-height: 40px;
    height: 40px;
  }
  
  .react-select__value-container {
    height: 40px;
    padding: 0 6px;
  }
  
  .react-select__input {
    margin: 0px;
  }
  
  .react-select__indicators {
    height: 40px;
  }
  
  .tableau_add .ant-table {
    overflow: auto;
  }
  
  .ant-input {
    min-height: 40px;
  }
  .react-select-container {
    width: 100%;
  }
  
  .react-select__control {
    min-height: 40px;
    height: 40px;
  }
  
  .react-select__value-container {
    height: 40px;
    padding: 0 6px;
  }
  
  .react-select__input {
    margin: 0px;
  }
  
  .react-select__indicators {
    height: 40px;
  }
  
  .tableau_add .ant-table {
    overflow: auto;
  }
  
  .ant-input {
    min-height: 40px;
  }
  .react-select-container {
    width: 100%;
  }
  
  .react-select__control {
    min-height: 40px;
    height: 40px;
  }
  
  .react-select__value-container {
    height: 40px;
    padding: 0 6px;
  }
  
  .react-select__input {
    margin: 0px;
  }
  
  .react-select__indicators {
    height: 40px;
  }
  
  .tableau_add .ant-table {
    overflow: auto;
  }
  
  .ant-input {
    min-height: 40px;
  }
  .react-select-container {
    width: 100%;
  }
  
  .react-select__control {
    min-height: 40px;
    height: 40px;
  }
  
  .react-select__value-container {
    height: 40px;
    padding: 0 6px;
  }
  
  .react-select__input {
    margin: 0px;
  }
  
  .react-select__indicators {
    height: 40px;
  }
  
  .tableau_add .ant-table {
    overflow: auto;
  }
  
  .ant-input {
    min-height: 30px;
  }
        