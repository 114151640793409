@import '../../style.scss';

.superviseur{
    background: #fffefeef;
    @include mobileXR{
        width: 100%;
    }
    @include mobileSE{
        width: 100%;
    }
    .superviseur_wrapper{
        @include mobileXR{
            height: calc(100vh - 80px);
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            overflow: hidden;
        }
        .scroll-row{
            margin-top: 20px;
            width: 90%;
        }
        .superviseur_rows{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 25px;
            padding: 20px;
            .superviseur_row{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                padding: 20px;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
                border: 1px solid rgba(3, 3, 109, 0.171);
                border-radius: 5px;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
                .superviseur_img_rond{
                    background:#13add828;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .superviseur_img{
                        width: 35%;
                        height: auto;
                        object-fit: cover;
                    }

                }
                .superviseur_span{
                    font-size: .8rem;
                    color: rgb(3, 3, 109);
                }
            }
        }
    }
}