.sidebar{
    overflow-y: auto;
    position: relative;
    flex: 1.6;
    height: 100vh;
    top: 0;
    position: sticky;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    border-right: 1px solid #cfcfcf;
    color: #8b8b8b;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #1C1C1C!important;
}

/* Personnalisation de la barre de défilement */
.sidebar::-webkit-scrollbar {
    width: 5px; 
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.sidebar::-webkit-scrollbar-thumb {
    background: #254a72;
    border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #162941;
}

.sidebar_icons{
    width: 100%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 5px 20px 10px 0;
    border-bottom: 1px solid #13add859;
    border-left: 1px solid #13add859;
}

.menu-custom {
    background: #1C1C1C !important;
    color: #13AED8 !important;
}

.settings-submenu{
    font-size: 14px;
    color: #13AED8;
}
.sidebar_img{
    width: 200px;
    object-fit: cover;
}
.custom-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #13AED8;
    border-radius: 50%;
    margin-right: 8px;
  }

  @media screen and (max-width: 568px){
    .sidebar{
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
        transform: translateX(-100%);
        transition: transform 0.3s;
        z-index: 999;
    }
    .visible{
        z-index: 999;
        position: fixed;
        transform: translateX(0);
        z-index: 999;
    }
}