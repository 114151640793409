.topbar{
    height: 60px;
    width: 100%;
    position: sticky;
    padding: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    top: 0;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    border-bottom: 1px solid #cfcfcf;
    .topbar-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .topbar_right{
            display: flex;
            align-items: center;
            gap: 10px;
            .topbar_icons{
                display: flex;
                align-items: center;
                gap: 8px;
                .topbar_icon{
                    font-size: 1.1rem;
                }
                .topbar_user{
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .topbar_username{
                    font-size: .9rem;
                    color: #13AED8;
                }
            }
            .topbarOne{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                cursor: pointer;
                background:rgba(14, 193, 238, 0.068);
                color: #4d4d4d;
                height: 35px;
                width: 35px;
                border-radius: 50%;
            }
        }
    }
}